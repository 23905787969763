
import { Component, HostListener,OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { AppService } from '../../core/app.service';
import { RouterExtService } from '../../core/urlService';
import { Store, select} from '@ngrx/store';

import * as UserActions from '../../store/user.actions';
import * as fromUser from '../../store/user.selectors';
import { Iuser } from '../../store/users.model';
declare var $: any;


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})

export class InvoiceComponent implements OnInit{
  
  prev;
  title = 'scube';
  list:any;
  checkOrder:any;
  table:any;
  constructor(
    private router: Router,
  	private wowService: NgwWowService,
    private appService: AppService,
    private routerService: RouterExtService,
    private store:Store<any>,
    ) {

      this.routerService.getPreviousUrl();

      this.store.pipe(select(fromUser.getCheckOrder)).subscribe(
        result => {
              this.checkOrder = result;
        });
        
        this.store.pipe(select(fromUser.getUsers)).subscribe(
        result => {
              this.list = result;
           
        });

  }
  
  

	ngOnInit() {
  
    this.table = localStorage.getItem('table');
    this.prev =   localStorage.getItem('item_id')

  
	}
  
}

