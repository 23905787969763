import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router,NavigationStart } from '@angular/router';
import { AppService } from '../../core/app.service';
import { Store, select} from '@ngrx/store';
import { Location } from '@angular/common';
import * as UserActions from '../../store/user.actions';
import { RouterExtService } from '../../core/urlService';

import { SharedService } from '../../core/sharedData';
import * as fromUser from '../../store/user.selectors';
import { Iuser } from '../../store/users.model';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit {

  quantity: number = 1;
  id;
  prev;
  list:any;
  table:any;
  isAdd:boolean;
  isAdded:boolean;
  isTakeAway:boolean;
  popTitle:string;
  popSubtitle:string;
  value:string;
  checkOrder :any;
  string:any;

  constructor(
    private appService: AppService,
    private activeRoute: ActivatedRoute,
    private routerService: RouterExtService,
    private router: Router,
    private location: Location,
    private store:Store<any>,
  ) { 
      this.prev = this.routerService.getPreviousUrl();
      this.activeRoute.params.subscribe(params => {
        this.id = params['id'];
        this.GetListById(this.id);
      });

      this.store.pipe(select(fromUser.getType)).subscribe(
        result => {
             if(result.type !== 'TAKEAWAY'){
                this.isTakeAway = false;
                this.string = 'Please confirm the order in the cart page to start preparing the dish'
             }else{
              this.isTakeAway = true;
               this.string = 'Pay to confirm the order'
             }
      });

  }



  ngOnInit() {
  
    this.table = localStorage.getItem('table');
   
   
    localStorage.setItem('item_id', this.id)
  }

  back() {
    this.location.back();
  }

  GetListById(id: any) {
    this.appService.GetListById(id).subscribe(
        result => {
         
          this.list = result.data;
        },

      );
  }

  addToCart(type){
    let data = {
      _id:this.list._id,
      quantity:this.quantity
    }
    this.appService.addToCart(data).subscribe(
      result => {
        this.store.dispatch(new UserActions.AddUsersSuccess({ data:result }));
 
        if(type == 'button'){
          this.popTitle = 'Item Added to Cart';
          this.popSubtitle = this.string;
          this.value = 'normal';
          this.isAdd = true;
          this.isAdded = true;
        }
       
      },

    );
  }

 
  onIncrement(type): void {
      this.quantity += 1;
      this.addToCart(type);
    }
   
  onDecrement(type): void {
      if(this.quantity > 1){
        this.addToCart(type);
      }
      this.quantity -= 1;
      if(this.quantity <= 1){
           this.quantity = 1;  
        }
    }

    paynow(){
      let data = {
        table:localStorage.getItem("table")
      }
      let order_id = localStorage.getItem("order_id");
      
     
      if(localStorage.getItem("order_id")){
        data["order_id"] = localStorage.getItem("order_id"); // update order
        this.appService.updateOrders(data).subscribe(
          result => {
            this.store.dispatch(new UserActions.LoadCheckOrderSuccess({ data:result }));
            localStorage.setItem("order_id", result.data._id);
            this.router.navigate(['/invoice']);
          },
          error =>{
            alert(error)
          }
        );
      }else{

        this.appService.createOrders(data).subscribe(
          result => {
            this.store.dispatch(new UserActions.LoadCheckOrderSuccess({ data:result }));
            localStorage.setItem("order_id", result.data._id);
            this.router.navigate(['/invoice']);
          },
          error =>{
            alert(error)
          }
        );
      }
    }
    confirmOrder(){
      let data = {
        table:localStorage.getItem("table")
      }
      let order_id = localStorage.getItem("order_id");
      
      if(localStorage.getItem("order_id")){
        data["order_id"] = localStorage.getItem("order_id"); // update order
        this.appService.updateOrders(data).subscribe(
          result => {
           
            this.store.dispatch(new UserActions.LoadCheckOrderSuccess({ data:result }));
            localStorage.setItem("order_id", result.data._id);
            
            this.popTitle = 'Your Order is Confirmed';
            this.popSubtitle = 'Your order will be taking 20-30 mins to be prepared and served';
            this.value = 'confirm';
            this.isAdded = true;
          },
          error =>{
            alert(error)
          }
        );
      }else{

        this.appService.createOrders(data).subscribe(
          result => {
            this.store.dispatch(new UserActions.LoadCheckOrderSuccess({ data:result }));
            localStorage.setItem("order_id", result.data._id);
            this.popTitle = 'Your Order is Confirmed';
            this.popSubtitle = 'Your order will be taking 20-30 mins to be prepared and served';
            this.value = 'confirm';
            this.isAdded = true;
          },
          error =>{
            alert(error)
          }
        );
      }
      
    }
    ok(value){
      this.isAdded = false;
      if(value == 'confirm'){
        this.router.navigate(['/invoice'])
      }
    
    }
    listView(id){
      localStorage.setItem('item_id', this.id)
      this.router.navigate(['/item-details/', id]);
      this.GetListById(id);
      this.isAdd = false;
    }

 
  }
