import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-table',
  templateUrl: './not-table.component.html',
  styleUrls: ['./not-table.component.scss']
})
export class NotTableComponent implements OnInit {
  table:any;
  constructor() { }

  ngOnInit() {
    this.table = localStorage.getItem('table');
  }

}
