import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule, DatePipe } from "@angular/common";

// import { NgwWowModule } from "ngx-wow";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { CommonModuleModule } from "./common-module/common-module.module";

import { AppRoutingModule } from "./app-routing.module";

// Service file
import { HttpClientHelperService } from "./core/Http/http-client-helper.service";
import { CitGlobalContantsService } from "./core/globalUrl/cit-global-contants.service";
import { RouterExtService } from "./core/urlService";

import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";

import { Ng2SearchPipeModule } from "ng2-search-filter";

import { StoreModule } from "@ngrx/store";
import {
  reducers,
  metaReducers,
  reducerProvider,
  REDUCERS_TOKEN,
} from "./store/reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { UserEffects } from "./store/user.effects";

import { Ng5SliderModule } from "ng5-slider"; //range silder

import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { ItemDetailsComponent } from "./pages/item-details/item-details.component";
import { scanCodeComponent } from "./pages/scan-code/scan-code.component";
import { PaymentSuccessComponent } from "./pages/payment-success/payment-success.component";
import { NotTableComponent } from "./pages/not-table/not-table.component";
import { ProceedPayComponent } from "./pages/proceed-pay/proceed-pay.component";
import { FeedbackComponent } from "./pages/feedback/feedback.component";
import { InvoiceComponent } from "./pages/invoice/invoice.component";
import { PaymentFailureComponent } from "./pages/payment-failure/payment-failure.component";
import { NgxStarRatingModule } from "ngx-star-rating";
import { WaitingPageComponent } from "./pages/waiting-page/waiting-page.component";
import { ThankyouComponent } from "./pages/thankyou/thankyou.component";
import { TakeawayComponent } from "./pages/takeaway/takeaway.component";
import { HomeMenuComponent } from "./pages/home-menu/home-menu.component";
// Guard services
import { AuthGuard } from "./core/guards/auth.guard";

import { InfiniteScrollModule } from "ngx-infinite-scroll";

// Auth services
import { AuthService } from "./core/auth/auth.service";
import { JwtInterceptor, ErrorInterceptor } from "./core/helpers";
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ItemDetailsComponent,
    scanCodeComponent,
    NotTableComponent,
    ProceedPayComponent,
    FeedbackComponent,
    InvoiceComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent,
    WaitingPageComponent,
    ThankyouComponent,
    TakeawayComponent,
    HomeMenuComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    FormsModule,
    SwiperModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    CommonModule,
    CommonModuleModule,
    BrowserAnimationsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    Ng5SliderModule,
    NgxStarRatingModule,
    AppRoutingModule,
    StoreModule.forRoot(REDUCERS_TOKEN, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([UserEffects]),
  ],
  providers: [
    reducerProvider,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe,
    CitGlobalContantsService,
    RouterExtService,
    HttpClientHelperService,
    AuthGuard,
    AuthService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
