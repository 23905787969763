


import { Component, HostListener,OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { AppService } from '../../core/app.service';
import { map } from 'rxjs/operators';
import { Store, select} from '@ngrx/store';
import { SharedService } from '../../core/sharedData';
import * as UserActions from '../../store/user.actions';
import * as fromUser from '../../store/user.selectors';
import { Iuser } from '../../store/users.model';
declare var $: any;


@Component({
  selector: 'app-cartIcon',
  templateUrl: './cartIcon.component.html',
  styleUrls: ['./cartIcon.component.scss']
})

export class CartIconComponent implements OnInit{
  title = 'scube';
  data :any;
  checkOrder :any;
  list :any;
  constructor(
    private router: Router,
  	private wowService: NgwWowService,
	  private appService: AppService,
	  private sharedService: SharedService,
    private store:Store<any>,
    ) {	

     

      this.store.dispatch(new UserActions.LoadCheckOrder()); //api call
      this.store.dispatch(new UserActions.LoadUsers()); //api call

      this.store.pipe(select(fromUser.getUsers)).subscribe(
        result => {
            this.list = result;
      });
      
      this.store.pipe(select(fromUser.getCheckOrder)).subscribe(
        result => {
       
              this.checkOrder = result;
              this.data = Object.keys(this.checkOrder).length;
          
              if(this.data > 0){
                
                if(this.checkOrder.data.table == false){
                    localStorage.removeItem("order_id");
                    this.router.navigate(['/invalid-table']);
                }else if(this.checkOrder.data.status == 'PROCEED_TO_PAYMENT' && this.checkOrder.data.payment_type == 'OFFLINE' ){
                    this.router.navigate(['/waiting']);
                }else if(this.checkOrder.data.status == 'PROCEED_TO_PAYMENT' && this.checkOrder.data.payment_type == 'ONLINE'){
                    this.router.navigate(['/proceed-pay']);
                }else if(this.checkOrder.data.status == 'COMPLETED'){
                  this.router.navigate(['/home']);
              }
                
                if(this.checkOrder.data._id){
                    localStorage.setItem("order_id", this.checkOrder.data._id);
                  }else{
                    localStorage.removeItem("order_id");
                  }
              }
  
      });
     
    }


	ngOnInit() {

    
      
      
	}
  
  clickMe(){
    this.sharedService.sendClickEvent();
    }
    
}
