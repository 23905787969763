import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute, Params } from "@angular/router";
import { AppService } from "../../core/app.service";
import { merge, Subject, Observable, of as observableOf } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, delay } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import { SharedService } from "../../core/sharedData";
import * as UserActions from "../../store/user.actions";
import * as fromUser from "../../store/user.selectors";
import { Order } from "../../store/orders.model";
import { AuthService } from "../../core/auth/auth.service";

declare var $;

@Component({
  selector: "app-home-menu",
  templateUrl: "./home-menu.component.html",
  styleUrls: ["./home-menu.component.scss"],
})
export class HomeMenuComponent implements OnInit {
  selectedItem: string;
  searchText: string;
  size: any;
  table: any;
  page: any = 1;
  category_id: any;
  total: any;
  type;
  categories = [];
  list = [];
  moreBoxes = [];

  public filterSubject = new Subject<string>();

  constructor(
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthService,
    private sharedService: SharedService,
    private store: Store<any>
  ) {
    this.type = this.activatedRoute.snapshot.params["type"];
  }

  ngOnInit() {
    this.getCategories();
  }

  listClick(event, id) {
    this.page = 1;
    this.list = [];
    this.moreBoxes = [];
    this.selectedItem = id; // don't forget to update the model here
    this.getList(id);
  }

  getCategories() {
    this.appService.getCategories().subscribe(
      (result) => {
        this.categories = result.data;
        this.listClick("default", this.categories[0]._id);
      },
      (error) => {
        console.log("error");
      }
    );
  }

  getSearch(query) {
    if (query.length > 0 && query != "") {
      $("#grouplist").hide();
      this.appService.getSearch(this.page, query).subscribe(
        (result) => {
          this.list = result.data.items;
          this.total = result.data.total;
        },
        (error) => {
          console.log("error");
        }
      );
    } else {
      this.getList(this.selectedItem);
      $("#grouplist").show();
    }
  }

  getList(id) {
    this.category_id = id;
    this.appService
      .getHomeList(this.category_id, this.page, this.type)
      .subscribe(
        (result) => {
          this.list = result.data.items;
          this.total = result.data.total;
        },
        (error) => {
          console.log("error");
        }
      );
  }

  listView(id) {
    this.router.navigate(["/item-details/", id]);
  }

  onScroll(e) {
    if (this.list.length >= this.total) {
      console.log("No more items");
      return;
    }
    this.page += 1;
    console.log("scrolled!!", this.list);
    this.appService
      .getHomeList(this.category_id, this.page, this.type)
      .subscribe(
        (result) => {
          console.log(result);
          this.moreBoxes = result.data.items;
          this.list = [...this.list, ...this.moreBoxes];
        },
        (error) => {
          console.log("error");
        }
      );
    // console.log( this.moreBoxes);
  }
}
