import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser/";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { ItemDetailsComponent } from "./pages/item-details/item-details.component";
import { scanCodeComponent } from "./pages/scan-code/scan-code.component";
import { HomeMenuComponent } from "./pages/home-menu/home-menu.component";
// import { PaymentSuccessComponent } from "./pages/payment-success/payment-success.component";
import { NotTableComponent } from "./pages/not-table/not-table.component";
// import { ProceedPayComponent } from "./pages/proceed-pay/proceed-pay.component";
// import { FeedbackComponent } from "./pages/feedback/feedback.component";
// import { InvoiceComponent } from "./pages/invoice/invoice.component";
// import { PaymentFailureComponent } from "./pages/payment-failure/payment-failure.component";
// import { WaitingPageComponent } from "./pages/waiting-page/waiting-page.component";
// import { ThankyouComponent } from "./pages/thankyou/thankyou.component";
// import { TakeawayComponent } from "./pages/takeaway/takeaway.component";

// Import canActivate guard services
import { AuthGuard } from "./core/guards/auth.guard";

const routes: Routes = [
  { path: "", redirectTo: "/invalid-table", pathMatch: "full" },

  { path: "home/:table", component: scanCodeComponent },

  {
    path: "home",
    loadChildren: () =>
      import("./home-module/home.module").then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },

  {
    path: "home-detail/:type",
    component: HomeMenuComponent,
    canActivate: [AuthGuard],
  },

  // { path: "thankyou", component: ThankyouComponent,  canActivate: [AuthGuard] },
  // { path: "invoice", component: InvoiceComponent,  canActivate: [AuthGuard] },
  // { path: "feedback", component: FeedbackComponent,  canActivate: [AuthGuard] },
  // { path: "takeaway", component: TakeawayComponent,  canActivate: [AuthGuard] },
  // { path: "proceed-pay", component: ProceedPayComponent,  canActivate: [AuthGuard] },

  {
    path: "item-details/:id",
    component: ItemDetailsComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: "waiting",
  //   component: WaitingPageComponent,
  //   canActivate: [AuthGuard],
  // },

  // { path: "payment-success", component: PaymentSuccessComponent,  canActivate: [AuthGuard] },
  // { path: "payment-failure", component: PaymentFailureComponent,  canActivate: [AuthGuard] },

  { path: "invalid-table", component: NotTableComponent },

  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false }),
    CommonModule,
    BrowserModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
