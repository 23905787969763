import { Component, HostListener,OnInit,ViewChild } from '@angular/core';
import {Router,NavigationEnd, ActivatedRoute, Params} from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { AppService } from './core/app.service';
import { RouterExtService } from './core/urlService';
import { SharedService } from './core/sharedData';
import { Store, select} from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as UserActions from './store/user.actions';
import * as fromUser from './store/user.selectors';
import { Iuser } from './store/users.model';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit{
  title = 'scube';
  table:any;
  isShow: boolean;
  isAdded: boolean;
  isRemove: boolean;
  size: number;

  prev;
  payBtn:boolean = false;
  isEdit= {};
  quantity:number = 1;
  list = [];
  users:Iuser[]=[];
  popTitle:string;
  popSubtitle:string;
  value:string;
  index:any;

  topPosToStartShowing = 100;
  clickEventsubscription:Subscription;
//   @HostListener("window:scroll", ["$event"]);
@ViewChild('closebutton',{static:false}) closebutton;
  constructor(
    private router: Router,
	private wowService: NgwWowService,
	private activatedRoute: ActivatedRoute,
	private appService: AppService,
	private sharedService: SharedService,
    private store:Store<any>,
    ) {
		

	 this.wowService.init();


	 this.router.events.subscribe(
	  (event: any) => {
	    window.scrollTo(0, 0);
	    if (event instanceof NavigationEnd) {
		
			if(this.router.url == '/product'){
				$('body').addClass('');
			}else{
				$('body').removeClass('product-mobile');
			}

	    	if(this.router.url == '/home'){
	    		 $('body').addClass('home');
	    	}else{
	    		$('body').removeClass('home');
	    	}
	    }
	  });

	
	  this.clickEventsubscription = this.sharedService.getLoadTable().subscribe(()=>{
		// this.tableLoad();
	})

	this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(()=>{
		this.incrementCount();
	  })
	  
	 

	}

	ngOnInit() {
	
	}

	// tableLoad(){
	
	// }
	
	incrementCount(){
		let table = localStorage.getItem("table");
		if(table != null){
			this.getList();
		}
		
	}

	
	checkScroll() {
      
		// window의 scroll top
		// Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
	
		const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
	
	
		if (scrollPosition >= this.topPosToStartShowing) {
		  this.isShow = true;
		} else {
		  this.isShow = false;
		}
	  }

	  // TODO: Cross browsing
	gotoTop() {
		window.scroll({ 
		top: 0, 
		left: 0, 
		behavior: 'smooth' 
		});
	}



	getList(){
		
		this.appService.GeMyCart().subscribe(
		  result => {
			this.list = result.data.items;
			var self = this;
			
			let l = [];
			let k = this.list.find(x => {
			  if(x.order_confirmed == false){
				  	l.push(x);
				}
			});

			if( l.length > 0 ){
				this.payBtn = true;
			}else{
				this.payBtn = false;
			}
		  },
		  error=> {
			console.log("error");
		  }
	  )}
	
	deleteItem(index){
		this.popTitle = 'Remove Item from Cart?';
		this.popSubtitle = 'Are you sure you want to remove this item from your cart?';
		this.index = index;
		this.isRemove = true;
	}

	remove(index){
		this.appService.deleteCart(index).subscribe(
			result => {
				this.list = result.data.items;
				this.store.dispatch(new UserActions.RemoveUsers({ data:result }));
				this.isRemove = false;
				this.getList();
			},
			error => {
				console.log("error");
			}
		)
	}

	dontRemove(){
		this.isRemove = false;
	}
	
	
	addToCart(item){
		let data = {
		  _id:item._id,
		  quantity:item.quantity
		}
		this.appService.addToCart(data).subscribe(
		  result => {
			this.store.dispatch(new UserActions.AddUsersSuccess({ data:result }));
		
		  },
	
		);
	  } 
	 
	
	  confirmOrder(){
		let data = {
		  table:localStorage.getItem("table")
		  
		}
		let order_id = localStorage.getItem("order_id");
		
		if(localStorage.getItem("order_id")){
		  data["order_id"] = localStorage.getItem("order_id"); // update order
		  this.appService.updateOrders(data).subscribe(
			result => {

			  this.store.dispatch(new UserActions.LoadCheckOrderSuccess({ data:result }));
			  localStorage.setItem("order_id", result.data._id);
		
			  this.popTitle = 'Your Order is Confirmed';
			  this.popSubtitle = 'Your order will be taking 20-30 mins to be prepared and served';
			  this.value = 'confirm';
			  this.isAdded = true;
			  
			},
			error =>{
			  alert(error)
			}
		  );
		}else{
  
		  this.appService.createOrders(data).subscribe(
			result => {
			  this.store.dispatch(new UserActions.LoadCheckOrderSuccess({ data:result }));
			  localStorage.setItem("order_id", result.data._id);
			    this.popTitle = 'Your Order is Confirmed';
			  this.popSubtitle = 'Your order will be taking 20-30 mins to be prepared and served';
			  this.value = 'confirm';
			  this.isAdded = true;
			},
			error =>{
			  alert(error)
			}
		  );
		}
		
	  }
  
	  ok(value){
		this.isAdded = false;
		if(value == 'confirm'){
			this.closebutton.nativeElement.click();
		  	this.router.navigate(['/invoice'])
		}
	  
	  }
	
	  goHome(){
		this.router.navigate(['/home']);
	  }

	  listView(id){
		this.router.navigate(['/item-details/', id]);
	  }
	
	  onIncrement(item): void {
		  item.quantity++;
		  this.addToCart(item);
		}
	   
	  onDecrement(item,i): void {
		  item.quantity -= 1;
		  if(item.quantity != 0){
			this.addToCart(item);
		  }
		  if(item.quantity < 1){
				item.quantity = 1
				this.deleteItem(i);
			}
		}
	
}
