import { Injectable } from '@angular/core';
import { Actions, Effect,ofType } from '@ngrx/effects';
import {of, Observable} from 'rxjs';
import { Action } from '@ngrx/store';
import * as userActions from './user.actions';

import { AppService} from '../core/app.service';

import {mergeMap, map ,catchError} from 'rxjs/operators'


@Injectable()
export class UserEffects {

  constructor(private actions$: Actions,private appService: AppService) {}

  @Effect()
  loadCheckOrder$: Observable<Action>= this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoadCheckOrder),
    mergeMap(
      action => this.appService.checkOrder().pipe( 
        map(orders => (new userActions.LoadCheckOrderSuccess({data:orders}))),
        catchError(err => of(new userActions.LoadCheckOrderFailure({error:err})))
      )
    )
  )

  @Effect()
  loadUsers$: Observable<Action>= this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoadUsers),
    mergeMap(
      action => this.appService.GeMyCart().pipe( 
        map(users => (new userActions.LoadUsersSuccess({data:users}))),
        catchError(err => of(new userActions.LoadUsersFailure({error:err})))
      )
    )
  )

}
