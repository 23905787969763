import { Component, OnInit } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';
import { interval } from 'rxjs';
import {AppService} from '../../core/app.service';
import { Store, select} from '@ngrx/store';

import * as UserActions from '../../store/user.actions';
import * as fromUser from '../../store/user.selectors';

@Component({
  selector: 'app-takeaway',
  templateUrl: './takeaway.component.html',
  styleUrls: ['./takeaway.component.scss']
})
export class TakeawayComponent implements OnInit {

  token:any;
  interval:any;
  constructor(
    private activeRoute: ActivatedRoute,
    private store:Store<any>,
    private appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
    this.confirmPayment();
    
    this.interval = setInterval(() => {
      this.confirmPayment(); 
    }, 5000);
   
  }

  confirmPayment() {
   let id = localStorage.getItem('order_id');
   let table = localStorage.getItem('table');

    this.appService.confirmPayment(id).subscribe(
      (result) => {
        this.token = result.data.token;
       
      if(result.data.status == 'COMPLETED' && result.data.is_completed == true){
          this.store.dispatch(new UserActions.LoadCheckOrderSuccess({ data:result }));
          clearInterval(this.interval);
          this.router.navigate(['/thankyou']);
        } 
 
      },
      (error) => {
        console.log(error)
      }
    );
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

}
