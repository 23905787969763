import { Action } from '@ngrx/store';
import {Iuser} from './users.model';
import {Order} from './orders.model';

export enum UserActionTypes{
  LoadCheckOrder = '[Order] Load Order',



  LoadUsers = '[User] Load Users',

  LoadCheckOrderSuccess = '[Order] Load Success',

  LoadUsersSuccess = '[User] Load Success',
  LoadUsersFailure = '[User] Load Failure',

  LoadCheckOrderFailure = '[Order] Load Failure',

  RemoveUsers = '[User] Remove',

  AddUsers = '[User] UPDATE',
  AddUsersSuccess = '[User] UPDATE Success',
  AddUsersError = '[User] UPDATE Error',
}


export class AddUsers implements Action {
  readonly type = UserActionTypes.AddUsers;
  constructor(public payload: {data:Iuser}) {}
}

export class AddUsersSuccess implements Action {
  readonly type = UserActionTypes.AddUsersSuccess;
  constructor(public payload: {data:Iuser}) {}
}
export class AddUsersError implements Action {
  readonly type = UserActionTypes.AddUsersError;
  constructor(public payload: {error:string}) {}
}

export class RemoveUsers implements Action {
  readonly type = UserActionTypes.RemoveUsers;
  constructor(public payload: {data:Iuser}) {}
}


export class LoadUsers implements Action {
  readonly type = UserActionTypes.LoadUsers;
}

export class LoadUsersSuccess  implements Action{
  readonly type = UserActionTypes.LoadUsersSuccess;
  constructor(public payload:{data:Iuser}){}
}

export class LoadUsersFailure  implements Action{
  readonly type = UserActionTypes.LoadUsersFailure;
  constructor(public payload:{error:string}){}
}


export class LoadCheckOrder implements Action {
  readonly type = UserActionTypes.LoadCheckOrder;
}

export class LoadCheckOrderSuccess  implements Action{
  readonly type = UserActionTypes.LoadCheckOrderSuccess;
  constructor(public payload:{data:Order}){}
}

export class LoadCheckOrderFailure  implements Action{
  readonly type = UserActionTypes.LoadCheckOrderFailure;
  constructor(public payload:{error:string}){}
}



export type UserActions = LoadUsers | LoadUsersSuccess | LoadUsersFailure  | RemoveUsers 
 | AddUsers | AddUsersSuccess | AddUsersError | LoadCheckOrderFailure | LoadCheckOrderSuccess | LoadCheckOrder;
