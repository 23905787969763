import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";


import { HeaderComponent } from "./header/header.component";
import { CartIconComponent } from "./cartIcon/cartIcon.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// import { SwiperModule } from 'ngx-swiper-wrapper';
// import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
// import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

// const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
//   direction: 'horizontal',
//   slidesPerView: 'auto'
// };
@NgModule({
  
  declarations: [
    HeaderComponent,
    CartIconComponent,
  ],
  // SwiperModule
  imports: [CommonModule,RouterModule,FormsModule,ReactiveFormsModule],
  exports: [
    HeaderComponent,
    CartIconComponent,
  ],
  // providers: [{
  //   provide: SWIPER_CONFIG,
  //   useValue: DEFAULT_SWIPER_CONFIG
  // }],
})
export class CommonModuleModule {}
