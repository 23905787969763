import { Action, createReducer, on } from '@ngrx/store';
import { Iuser } from './users.model';
import { Order } from './orders.model';
import { UserActions, UserActionTypes } from './user.actions';


export const userFeatureKey = 'usersState';

export const checkOrderKey = 'checkOrderState';


export interface State {
  users:any,
  error:string,
  orders: any,
  type: any,
  meta: any,
}

export const initialState:State = {
  users:'',
  error:'',
  orders:'',
  type:'',
  meta:''
}


export function reducer(state = initialState, action:UserActions):State{
  switch(action.type){

    case UserActionTypes.LoadCheckOrder:
      return{
        ...state
      }

    case UserActionTypes.LoadCheckOrderSuccess:
        return{
          ...state,
          orders:action.payload.data,
          type:action.payload.data.data,
          error:''
        }

    case UserActionTypes.LoadCheckOrderFailure:
      return{
        ...state,
        orders:[],
        error:action.payload.error
      }

    case UserActionTypes.LoadUsers:
      return{
        ...state
      }

    case UserActionTypes.LoadUsersSuccess:
      return{
        ...state,
        users:action.payload.data,
        error:''
      }

    case UserActionTypes.LoadUsersFailure:
      return{
        ...state,
        users:[],
        error:action.payload.error
      }

      case UserActionTypes.AddUsersSuccess:
        return {
          ...state,
          users:action.payload.data,
          error:''
        }

    case UserActionTypes.RemoveUsers:
      return {
        ...state,
        users:action.payload.data,
        error:''
      }

    default:
      return state;
  }
}

