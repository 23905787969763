import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Table } from '../table.service';
import { AppService } from '../app.service';
import { CitGlobalContantsService } from '../globalUrl/cit-global-contants.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { Store, select} from '@ngrx/store';
import { SharedService } from '../../core/sharedData';
import * as UserActions from '../../store/user.actions';
import * as fromUser from '../../store/user.selectors';
import { Iuser } from '../../store/users.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentTableSubject: BehaviorSubject<Table>;
  public currentTable: Observable<Table>;

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private globalUrl: CitGlobalContantsService,
    private store:Store<any>,
  ) {
    this.currentTableSubject = new BehaviorSubject<Table>(JSON.parse(localStorage.getItem('currentTable')));
    this.currentTable = this.currentTableSubject.asObservable();
  }

  public get currentTableValue(): Table {
    return this.currentTableSubject.value;
  }


  checkTable(table) {
    return this.http.post<any>(this.globalUrl.tokenUrl + '/' + table, {}, { observe: 'response' })
      .pipe(map(table => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentTable', JSON.stringify(table.body));
        this.currentTableSubject.next(table.body);
        return table.body;
      }
      
      ));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentTable');
    this.currentTableSubject.next(null);
  }
}