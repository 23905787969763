import { Component, OnInit, Input  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterExtService } from '../../core/urlService';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';
import {AppService} from '../../core/app.service';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, select} from '@ngrx/store';
import { SharedService } from '../../core/sharedData';
import * as UserActions from '../../store/user.actions';
import * as fromUser from '../../store/user.selectors';
import { Iuser } from '../../store/users.model';

@Component({
  selector: 'app-waiting-page',
  templateUrl: './waiting-page.component.html',
  styleUrls: ['./waiting-page.component.scss']
})
export class WaitingPageComponent implements OnInit {

  interval:any;
  isTakeAway:any;
  string:any;
  constructor(
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private router: Router,
    private store:Store<any>,
  ) { 

    this.activeRoute.queryParams.subscribe(params => {
  
    });

    this.store.pipe(select(fromUser.getType)).subscribe(
      result => {
           if(result.type == 'TAKEAWAY'){
              this.string = 'Token:- ' +  result.token
           }else{
              this.string = 'Please wait, while the payment is being accepted'
           }
    });
   
  }

  ngOnInit() {

    this.confirmPayment();

    this.interval = setInterval(() => {
      this.confirmPayment(); 
    }, 5000);

  }

  confirmPayment() {
   let id = localStorage.getItem('order_id');
    this.appService.confirmPayment(id).subscribe(
      (result) => {
      if(result.data.status == 'COMPLETED' && result.data.type == 'DINEIN'){
          clearInterval(this.interval);
          this.router.navigate(['/feedback']);
        } 
        
      if(result.data.status == 'COMPLETED' && result.data.type == 'TAKEAWAY'){
          clearInterval(this.interval);
          this.router.navigate(['/takeaway']);
        }
 
      },
      (error) => {
        console.log(error)
      }
    );
  }

  
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }


}
