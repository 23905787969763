import { Component, OnInit } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    localStorage.removeItem("order_id");
  }

  homePage(){
    this.router.navigate(['/home']);
  }
}
