import { Component, OnInit } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';
import { interval } from 'rxjs';
import {AppService} from '../../core/app.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  interval:any;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) { }



  ngOnInit() {
    this.confirmPayment();
    this.interval = setInterval(() => {
      this.confirmPayment(); 
    }, 5000);
  }

  confirmPayment() {
   let id = localStorage.getItem('order_id');
    this.appService.confirmPayment(id).subscribe(
      (result) => {
      if(result.data.status == 'COMPLETED' && result.data.type == 'DINEIN'){
          clearInterval(this.interval);
          this.router.navigate(['/feedback']);
        } 
        
      if(result.data.status == 'COMPLETED' && result.data.type == 'TAKEAWAY'){
          clearInterval(this.interval);
          this.router.navigate(['/takeaway']);
        }
 
      },
      (error) => {
        console.log(error)
      }
    );
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

}
