import { Component, OnInit } from '@angular/core';
import { RouterExtService } from '../../core/urlService';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../core/app.service';

import { Store, select} from '@ngrx/store';
import { first } from 'rxjs/operators';
import * as UserActions from '../../store/user.actions';
import * as fromUser from '../../store/user.selectors';
import { Iuser } from '../../store/users.model';

import { Order } from '../../store/orders.model';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'app-scan-code',
  templateUrl: './scan-code.component.html',
  styleUrls: ['./scan-code.component.scss']
})
export class scanCodeComponent implements OnInit {

  prev;
  payBtn:boolean;
  isEdit= {};
  table:any;
  quantity:number = 1;
  list = [];
  users:Iuser[]=[];
  constructor(
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterExtService,
    private authenticationService: AuthService,
    private store:Store<any>,
    ) {
        
    
      localStorage.setItem('table', this.activatedRoute.snapshot.paramMap.get('table'));
      
      this.tables();
  }
 
  ngOnInit() {

  }

  tables(){

    this.authenticationService.checkTable(localStorage.getItem('table'))
    .pipe(first())
    .subscribe(
      data => {
        this.router.navigate(['/home']);
      },
      error => {
        this.router.navigate(['/invalid-table']);
      });
  }

}
